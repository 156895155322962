*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: sans-serif;
}

/* ------------------------    Scrollbar ----------------------------------------------------*/

::-webkit-scrollbar {
  width: 1.4rem;
  height: 100%;
}

::-webkit-scrollbar-track {
  background: hsl(120, 5%, 67%);
  border-radius: 100vw;
  margin-block: 0.4rem;
}

.projects-list-outer::-webkit-scrollbar-track {
  margin-bottom: 3.2rem;

}

::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 23%);
  border: 3px solid hsl(120, 8%, 92%);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(120 100% 10% / 1);
}


/* ------------------------    Header ----------------------------------------------------*/

.header-cont-outer {
  /* background: red; */
  background: #080f2b;
  width:100%;
  height:6vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom:1px solid rgba(128, 0, 255, 0.2);
}

.header-cont-inner {
  position:absolute;
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background:gold; */
  /* padding:0 2rem; */
}

/* #header-logo {
  width:45px;
  height:45px;
  position:relative;
  }
  
  #header-logo:hover {
    cursor: pointer;
    } */
    
.header-cont-inner h2{
  color:#fff;
  font-size: 1.2rem;
      
}

#header-projects:hover {
  cursor: pointer;
  color:red
}

/* img {
  position:absolute;
  width:100%;
  height:100%;
} */

/* ------------------------    Home ----------------------------------------------------*/


.home-cont-outer {
  width:100vw;
  height:100vh;
  background:#020f1f;
  /* position: relative; */
}

.home-cont-inner {
  /* position: absolute; */
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

/* ------------------------    Projects Filters ----------------------------------------------------*/

.activeFilter {
  background:#453f08;
  border:1px solid rgba(255, 238, 0, 0.4);
}

#filters {
  font-size:1.35rem;
  text-decoration: underline;
  color: #ad9e9e;
  text-shadow: 1px 2px 1px 1px rgba(225, 211, 211, 0.4);
  font-weight: bold;
}

#reset {
  font-size:0.9rem;
  padding:0.3rem 0.7rem;
  border: 1px solid #000;
  border-radius: 2rem;
  cursor: pointer;
  color:red;
  background: #fff;
}

#results {
  font-size:1.2rem;
  color: #fff;
  /* text-shadow: 1px 2px 1px 1px rgba(225, 211, 211, 0.4); */
  font-weight: bold;
}

#reset:hover {
/* background:#000; */
background:red;
color:#fff;
}

.projects-outer-cont {
  min-height:100vh;
  width:100%;
  position: relative;

}

.projects-inner-cont {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background:darkcyan
}

.projects-filters-outer {
  width: 15vw;
  height:100%;
  /* background:rgb(141, 134, 141); */
  background: #494848;  
  border-right:1px solid #cec8c8;

}

.projects-filters-title {
  width: 100%;
  height: 6vh;
  /* background:red;  */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.projects-filters-content {
  width:100%;
  height:94vh;
  /* background:cyan; */
  position:relative;
  display: flex;
  flex-direction: column;
}

#filters-languages {
  position:absolute;
  width:100%;
  height:40vh;
  top:0vh;
  /* background:green; */
  padding:0.3rem;
}

#filters-frameworks {
  position:absolute;
  width:100%;
  height:37vh;
  top:35vh;
  /* background: rgb(181, 32, 32); */
  padding:0.3rem;

}

#filters-app-type {
  position:absolute;
  width:100%;
  height:28vh;
  top:66vh;
  /* background:gold; */
  padding:0.3rem;

}



/* #filters-cloud {
  position:absolute;
  width:100%;
  height:15vh;
  top:79vh;
  background:violet;
  padding:0.3rem;

} */

.filter-content {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display:flex;
  flex-direction: column;
  gap:0.2rem;
  padding: 0.3rem;


}

.filter-title {
  width:100%;
  height:3vh;
  /* border: 1px solid #000000; */
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: bold;
  color:#e35757;
  /* text-decoration: solid; */
}


.filter-options {
  width:100%;
  height:100%;
  /* border: 1px solid #000000; */
  padding-bottom: 1rem;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly; */
}

#options-language, #options-framework{
  width:85%;
  height:4.7vh;
  /* border:1px solid gold; */
  display:flex;
  align-items: center;
  justify-content: start;
  gap:3rem;
  padding:0.2rem;
  margin-left: 1rem;
  margin-bottom:0.3rem;
  color: #c0b9b9;

}

#options-app-type {
  width:85%;
  height:4.7vh;
  /* text-align: center; */
  display:flex;
  align-items: center;
  justify-content: center;
  padding:0.2rem;
  margin-left: 1rem;
  margin-bottom:0.2rem;
  color: #c0b9b9;

}

#options-language:hover, #options-app-type:hover, #options-framework:hover{
  cursor: pointer;
  background:rgb(223, 198, 53);
  color: #000000;

}




#options-language .filter-logo, #options-app-type .filter-logo, #options-framework .filter-logo{
  position: relative;
  width:2rem;
  height:2rem;
  /* border:1px solid #000; */
  /* justify-content: start; */
  /* object-fit: contain; */
}

/* #options-language .filter-logo-cpp{
  position: relative;
  width:3.1rem;
  height:3rem;
  border:1px solid #000; */
  /* justify-content: start; */
  /* object-fit: contain;
} */

#options-language .filter-logo img, #options-app-type .filter-log img, #options-framework .filter-logo img{
  position:absolute;
  width:100%;
  height:100%;
  object-fit: contain;
  
}

#options-language h2, #options-app-type h2, #options-framework h2{
  font-size:0.9rem;
  /* color: #c0b9b9; */
  /* border:1px solid #000; */
}





/* .filter-options h2{
  font-size: 0.8rem;
  border:1px solid #000;
  width:100%; 
  text-align: center;
  cursor: pointer;
}

.filter-options h2:hover{
  background:red;
} */

/* ------------------------    Projects List ----------------------------------------------------*/


.projects-content {
  width:85vw;
  height:100%;
  /* overflow-y: hidden; */
  background:rgb(63, 11, 216);
  position:relative;
}


.projects-metadata {
  width:100%;
  height:6vh;
  background:rgb(71, 70, 70);
  padding:1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9cece;
  color: #fff;
  
}

.projects-list-outer {
  position:absolute;
  /* height:100%; */
  /* min-height:94vh; */
  height:94vh;
  width:100%;
  background:#333;
  padding: 1rem 1.5rem 1.5rem 2.5rem;
  /* padding-left: 2rem; */
  /* padding-top:0.8rem;   */
  /* margin-bottom:0.5rem; */
  overflow-y: auto;
}

.projects-list-inner {
  width:100%;
  min-height:100%;
  /* background:mediumseagreen; */
  display: grid;
  grid-template-columns: repeat(4,1fr);
  row-gap: 1rem;
  /* gap: 1.2rem; */
  /* padding-bottom:4rem; */
  /* margin-bottom:1rem; */
  /* margin-bottom:1rem; */
  /* justify-content: center; */
}

/* ------------------------    Card ----------------------------------------------------*/

.card-outer-cont {
  position:relative;
  width:250px;
  height:320px;
  /* border: 1px solid #000; */
  /* background: royalblue; */
  border-radius: 1.3rem;
  cursor: pointer;
  /* box-shadow: 2px 4px 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 4px 2px 3px rgba(255, 255, 255, 0.3); */

}

/* .card-outer-cont:hover {
  cursor: pointer;
} */

.card-inner-cont {
  position:absolute;
  width:100%;
  height:100%;
  transform-style: preserve-3d;
  transition: all 0.8s ease-in-out;
  /* border: 1px solid #000; */
  /* background: black; */
  border-radius: 1.3rem;

}

/* .card-inner-cont:hover {
  transform: rotateY(180deg);
} */
/* cursor: pointer; */

.card-outer-cont:hover .card-inner-cont {
  transform: rotateY(180deg);
  /* cursor: pointer; */
}

.card-front {
  position: absolute;
  width:100%;
  height:100%;
  backface-visibility:hidden;
  /* background: rgb(164, 115, 198); */
  background: linear-gradient(150deg, #5b595b, #14053a 100%);
  border: solid 1px rgba(255, 255, 255, 0.3);
  border-radius: 1.3rem;
  /* text-align: center; */
  /* box-shadow: 2px 2px  rgba(0, 0, 0, 0.7); */
  /* box-shadow: -2px -2px  rgba(0, 0, 0, 0.8); */
  box-shadow: 0 0 0 2px  rgba(0, 0, 0, 1);
  /* box-shadow: 2px 1px 2px 1px rgba(255, 255, 255, 0.3); */
  display:flex;
  flex-direction: column;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.front-cloud {  
  width:100%;
  height:20%;
  /* border:1px solid #000; */
  /* background:red; */
  display: flex;
  align-items: start;
  justify-content: end;
  position:relative;
}

.front-title {
  width:100%;
  height:40%;
  /* border:1px solid #000; */
  /* background:green; */
  display:flex;
  align-items: end;
  justify-content: start;
}

.front-tags {
  width:100%;
  height:20%;
  /* border:1px solid #000; */
  /* background:goldenrod; */
  display:flex;
  align-items: end;
  justify-content: start;
  padding-bottom:0.3rem;
  padding-left: 0.3rem;
  column-gap: 0.5rem;
}

.front-tech-stack {
  width:100%;
  height:20%;
  /* border:1px solid #000; */
  /* background:magenta; */
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding:0.2rem 0.3rem;
}

.front-db-logo-cont {
  position:absolute;
  top:0;
  left:0;
  width:35%;
  height:80%;
  /* background:grey; */
  display: flex;
  align-items: center;
  justify-content: start;
  /* padding-left:0.5rem; */
}

.front-db-logo {
  position:relative;
  width:70%;
  height:75%;
  /* background:green; */
  /* padding-left: 0.5rem; */
  /* padding-top: 0.2rem; */
}

.front-db-logo img{
  position:absolute;
  width: 100%;
  height:100%;
  object-fit: contain;
}

.front-cloud-logo-cont {
  width:50%;
  height:80%;
  /* background:rgb(188, 188, 195); */
  /* padding:0.4rem; */
  /* position:relative; */
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  justify-content: end;
  column-gap: 0.15rem;;
  padding-right:0.5rem;
}

.front-cloud-logo {
  position:relative;
  width: 40%;
  height: 40%;
  /* background: #a9a2a2; */
  /* display: flex; */
  /* justify-content: space-evenly; */
  /* align-items: center; */
  /* border:1px solid #000; */
}

.front-cloud-logo img{
  position: absolute;
  width:100%;
  height:100%;
  /* object-fit: contain; */
}

.front-title h2{
  color:#fff;
  font-size:1.3rem;
  padding-left: 0.4rem;

  /* padding: 0; */
  margin:0;
  /* border: 1px solid #000; */
  /* border-radius: 1rem; */
}

.front-tags h2{
  color:#fff;
  font-size:0.7rem;
  padding: 2px 12px;
  border: 2px solid #140101;
  border-radius: 1rem;
}

.front-tech-stack-languages{
  width:45%;
  height:80%;
  /* background:rgb(52, 179, 120); */
  display: flex;
  align-items: end;
  justify-content: start;
  padding-left:0.3rem;
  padding-bottom:0.3rem;
  column-gap: 0.1rem;
}

.front-tech-stack-frameworks {
  width:45%;
  height:80%;
  /* background:grey; */
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right:0.3rem;
  padding-bottom:0.3rem;
  column-gap: 0.1rem;

}

.front-tech-stack-logo {
  position:relative;
  width: 35%;
  height: 70%;
  /* border:1px solid #000; */
}

.front-tech-stack-logo img {
  position: absolute;
  width:100%;
  height:100%;
  object-fit: contain;
}

.card-back {
  position: absolute;
  width:100%;
  height:100%;
  backface-visibility:hidden;
  transform: rotateY(180deg);
  /* background: indigo; */
  /* background: linear-gradient(150deg, #540954, #0a6e6e 100%); */
  background: linear-gradient(150deg, #5b595b, #14053a 100%);
  border: solid 1px rgba(255, 255, 255, 0.3);
  /* box-shadow: 2px 2px  rgba(0, 0, 0, 0.7); */
  box-shadow: 0 0 0 2px  rgba(0, 0, 0, 1);
  /* box-shadow: -2px -2px  rgba(0, 0, 0, 0.8); */

  border-radius: 1.3rem;
  /* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.4); */

  display:flex;
  flex-direction: column;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.back-description {
  width:100%;
  height:25%;
  /* border:1px solid #000; */
  /* background:red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-key-features {
  width:100%;
  height:45%;
  /* border:1px solid #000; */
  /* background:gold; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-deployment {
  width:100%;
  height:30%;
  /* border:1px solid #000; */
  /* background:green; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-description-content {
  width:90%;
  height:90%;
  /* background:blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border:2px solid #000; */
  text-align: center;
}

.back-description-content h2, .back-key-features-title h2 {
  font-size:0.85rem;
  color:#fff;
  /* border:1px solid #000; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

  /* padding:0 0.8rem; */
}

.back-key-features-cont {
  width:95%;
  height:95%;
  /* border:1px solid #000; */
  /* background:grey; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.2rem;

}

.back-key-features-title {
  width:95%;
  height:20%;
  /* background:red; */
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
}

.back-key-features-list {
  width:95%;
  height:80%;
  /* background:blue; */
  /* border:1px solid #000; */
  padding-left:1.7rem;
  /* display: flex;
  justify-content: start;
  align-items: start; */
}

.back-key-features-list ul li{
  color: #fff;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 0.85rem;
  padding-top:0.35rem;

}

.back-deployment-content {
  width:97%;
  height:90%;
  /* background:blue; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left:0.3rem;
  /* border:2px solid #000; */
  row-gap: 0.2rem;
  /* text-align: center; */
}

.back-deployment-content h2 {
  font-size: 0.75rem;
  color:#fff;
  /* border:1px solid #000; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}



/* ------------------------    Project Details ----------------------------------------------------*/

.project-details-outer {
  position:relative;
  width: 100vw;
  min-height:100vh;
  background: linear-gradient(175deg,#857a7a 40%,#635f5f 100%);
  /* display: flex;   */
  overflow-y: auto;
}

/* .project-details-inner {
  position:relative;
  width: 100vw;
  min-height:100vh;
} */

.metadata-cont {
  /* background: linear-gradient(175deg,#857a7a 40%,#635f5f 100%); */
  position:fixed;
  width:20%;
  height:100%;
  /* border-right:1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 0.7rem;
}

.information-cont {
  /* background: linear-gradient(175deg,#857a7a 40%,#635f5f 100%); */
  position:absolute;
  left: 20%;
  width:56%;
  /* min-height:100%; */
  min-height:100vh;
  /* border:1px solid gold; */
  /* border-right:1px solid #000; */
  /* border-left:1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* padding-top:1rem; */
  padding:1rem;
  /* overflow-y: auto; */
  row-gap: 1.5rem;;
}

.content-links-cont {
  /* background: linear-gradient(175deg,#857a7a 40%,#635f5f 100%); */
  position:fixed;
  left: 75%;
  width:23%;
  height:100%;
  /* border-left:1px solid red; */
  /* border:1px solid green; */
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top:1rem;
  /* padding-right:1rem; */
  /* overflow-y: auto; */

}

/* ------------------------    Project Details - My Details ----------------------------------------------------*/


.my-details-cont {
  width:90%;
  height:45%;
  border: 1px solid #000;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.7);
  border-radius: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* row-gap: 0.2rem; */
}

.profile-pic-cont-outer {
  width:95%;
  height:50%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pic-cont-inner {
  width:75%;
  height:100%;
  /* border:1px solid #000; */
  border-radius: 70%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pic-cont-inner img{
  position:absolute;
  width:70%;
  height:70%;
  object-fit: contain;
}

.profile-info-cont-outer {
  width:95%;
  height:40%;
  /* border:1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.2rem;
}

.name-cont {
  width:95%;
  height:25%;
  border-bottom:1px solid #000;
  padding-bottom:0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-cont h2{
  /* color:#fff; */
  font-size:1.2rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.personal-links-cont {
  width:95%;
  height:65%;
  /* border:1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
}

.personal-link-cont {
  width:98%;
  height:40%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.2rem;
  /* background:orange; */
}

.personal-link-logo-outer-cont {
  width:15%;
  height:90%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal-link-logo-inner-cont {
  width:90%;
  height:90%;
  position:relative;
}

.personal-link-logo-inner-cont img{
  position:absolute;
  width:100%;
  height:100%;
  object-fit: contain;
}

.personal-link-hyperlink-cont {
  width:85%;
  height:90%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal-link-hyperlink-cont a {
  text-decoration: none;
  font-size:0.9rem;
  color:#3c0b02;
  font-style: italic;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.personal-link-hyperlink-cont a:hover {
  /* color:#fc300c; */
  color:#ffffff;
}
.personal-link-hyperlink-cont a:active {
  color:#3c0b02;
}
/* .personal-link-hyperlink-cont a:visited {
  color:#3c0b02;
} */

/* ------------------------    Project Details - Project Links ----------------------------------------------------*/

.project-links-cont {
  width:90%;
  height:25%;
  border: 1px solid #000;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.7);
  border-radius: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.3rem;
}

.project-links-title-cont {
  height:25%;
  width:90%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom:1px solid #000;
}

.project-links-title-cont h2 {
  font-size: 1.2rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.project-link-hyperlinks-cont-outer {
  height:65%;
  width:95%;
  /* border:1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.9rem;
}

.project-link-cont {
  width:100%;
  height:30%;
  /* border:1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.3rem;
}

.project-logo-cont {
  position:relative;
  width:15%;
  height:80%;
  /* border:1px solid #000; */
}

.project-logo-cont img{
  position:absolute;
  width:100%;
  height:100%;
  object-fit: contain;
}

.project-hyperlink-cont {
  width:75%;
  height:100%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-hyperlink-cont a {
  text-decoration: none;
  font-size:0.85rem;
  color:#3c0b02;
  font-style: italic;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.project-hyperlink-cont a:hover {
  /* color:#fc300c; */
  color:#ffffff;
}
.project-hyperlink-cont a:active {
  color:#3c0b02;
}


/* ------------------------    Project Detail - Content Links ----------------------------------------------------*/

.content-links-outer{
  /* position: relative; */
  width:80%;
  /* min-height:50%; */
  max-height:95%;
  border:1px solid #000;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.7);
  border-radius:1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top:1rem;
  padding-bottom:1rem;
  row-gap: 1rem;
}

.content-links-title {
  width:90%;
  /* height:30%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border:1px solid green; */
  border-bottom: 1px solid #000;
  padding-bottom: 0.5rem;
}

.content-links-title h2{
  font-size: 1.3rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.content-links-inner {
  /* position:absolute; */
  width:90%;
  height:90%;
  /* border:1px solid red; */
  /* padding-top:0.5rem; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  row-gap:0.5rem;
  padding-left:0.5rem;
}

.content-links-container {
  width:90%;
  min-height:5%;
  /* border:1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  row-gap:0.2rem;
  /* padding:0.3rem; */
  
}

.container-links-mainLink {
  width: 90%;
  height:1.5rem;
  /* border:1px solid #000; */
  /* padding-left:1rem; */
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: bold;
}

.container-links-subLink {
  width: 90%;
  height:1.5rem;
  /* border:1px solid #000; */
  margin-left:1.2rem;
  display: flex;
  align-items: center;
  justify-content: start;
}

.content-links-container a {
  text-decoration: none;
  font-size:0.9rem;
  color:#3c0b02;
  font-style: italic;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.content-links-container a:hover {
  /* color:#fc300c; */
  color:#ffffff;
}
.content-links-container a:active {
  color:#3c0b02;
}



/* ------------------------    Project Details - Information ----------------------------------------------------*/

.chapter-cont-outer {
  width:100%;
  /* min-height:100px; */
  min-height:50px;
  /* border:3px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  row-gap: 1rem;
  padding:0.5rem;
}


/* ------------------------    info -----------------------------*/


.chapter-cont-inner-info {
  width:100%;
  height:60px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: start;
}

.info-inner-cont {
  /* position:relative; */
  width:100%;
  height:90%;
  /* border:1px solid #000; */
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 0.5rem;
}

.info-logo-cont{
  /* position:absolute; */
  width:10%;
  height:80%;
  /* border:1px solid red; */
  border-right:1px solid #000;
}

.info-logo-cont img{
  width: 100%;
  height:100%;
  object-fit: contain;
}

.info-text {
  width:100%;
  height:100%;
  /* border:1px solid green; */
  /* padding:1rem; */
  display: flex;
  align-items: center;
  justify-content: start;
}

.info-text h2{
  font-size:1rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-style: italic;
  padding-left:5px;
}

/* ------------------------    title -----------------------------*/

.chapter-cont-inner-title {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.chapter-cont-inner-title h1 {
  font-size:2.3rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


/* ------------------------    h2 -----------------------------*/

.chapter-cont-inner-h2 {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
  
}

.chapter-cont-inner-h2 h2 {
  font-size:1.9rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
}

/* ------------------------    h3 -----------------------------*/

.chapter-cont-inner-h3 {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
  
}

.chapter-cont-inner-h3 h3 {
  font-size:1.6rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

/* ------------------------    h4 -----------------------------*/

.chapter-cont-inner-h4 {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
  
}

.chapter-cont-inner-h4 h4 {
  font-size:1.3rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

/* ------------------------  p -----------------------------*/

.chapter-cont-inner-p {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
  
}

.chapter-cont-inner-p p {
  font-size:1.2rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

/* ------------------------  image -----------------------------*/

.chapter-cont-inner-imgCont {
  width:100%;
  /* height:100%; */
  height:500px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.7rem;
  /* padding-top:0.7rem; */
}

.imgCont-imgContainer {
  width:90%;
  height:85%;
  /* border:1px solid #000; */
}

.imgCont-imgContainer img{
  width:100%;
  height:100%;
  object-fit: contain;
  /* object-fit: cover; */
}

.imgCont-imgCaption {
  width:90%;
  height:10%;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------  table -----------------------------*/

.chapter-cont-inner-table {
  width:100%;
  /* height:100%; */
  height:500px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.7rem;
}

.table-caption {
  width:90%;
  height:10%;
  /* border:1px solid #000; */
  display:flex;
  align-items: center;
  justify-content: center;
}

.table-caption p {
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.table-tbl {
  width:90%;
  height:80%;
  border:1px solid #000;
}

table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

td, th {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border: 1px solid #000;
  padding: 8px;
}

th {
  color:#3c0b02;
  font-size:1.1rem;
  /* text-decoration: underline; */
  background:rgba(0, 0, 0, 0.4);
  text-align: center;
}

td {
  text-align: left;

}

tr:nth-child(even) {
  background-color: #4a4949;
}

/* tr:nth-child(odd) {
  background-color: #665151;
} */

/* ------------------------  ul -----------------------------*/

.chapters-cont-inner-ul {
  width:100%;
  min-height:50px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  /* row-gap: 0.1rem; */
  /* padding:0.5rem; */
}

.ul-title {
  width: 90%;
  min-height:30px;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left:5px;
}

.ul-ul {
  width: 90%;
  min-height:10px;
  /* border:1px solid #000; */
  padding-left:5%;
}

/* ------------------------  ol -----------------------------*/

.chapters-cont-inner-ol {
  width:100%;
  min-height:50px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  /* row-gap: 0.1rem; */
  /* padding:0.5rem; */
}

.ol-title {
  width: 90%;
  min-height:30px;
  /* border:1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left:5px;
}

.ol-ol {
  width: 90%;
  min-height:10px;
  /* border:1px solid #000; */
  padding-left:5%;
}

ul li, ol li {
  padding-bottom:0.5rem;
}

/* ------------------------  a -----------------------------*/

.chapters-cont-inner-a {
  width:100%;
  height:100%;
  /* border: 1px solid #000; */
  display: flex;
  align-items: start;
  justify-content: start;
}

.chapters-cont-inner-a a {
  text-decoration: none;
  font-size:0.9rem;
  color:#3c0b02;
  font-style: italic;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.chapters-cont-inner-a a:hover {
  /* color:#fc300c; */
  color:#ffffff;
}
.chapters-cont-inner-a a:active {
  color:#3c0b02;
}